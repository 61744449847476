import React, { useContext, useEffect, useState } from "react";
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom";
import firebase, { db } from "../utils/firebase.js";  // should we isolate logic in a seperate component ...? 
import { nanoid } from "nanoid";
import { Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material'
import theme from '../styles/theme'
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AuthBox from "./auth-box";
import toast from "react-hot-toast";
import Messages from "../constants/app.js";
import { formatDate } from '../utils/utility.js';


export default function ForkProject() {
    //includeSelectedOrLatest_watchable = true;  // not handled,  partially implemented and commented out
    //includeSelectedOrLatest_seleactable_proxy_and_veneer = false // idea: not handled, requires sending over takes
    const { state, dispatch } = useContext(AppContext);
    // const story = state.stories.activeStory //state.story //state.stories.activeStory
    // const takes = state.stories.activeTakeList
    // const shots = state.stories.activeShotList
    const user = state.userData
    const history = useHistory();
    const [forkOpen, setForkOpen] = useState(false)
    const [loginOpen, setLoginOpen] = useState(false)
    const [mediaOption, setMediaOption] = useState("Selected Media");
    const isLoggedIn = Object.keys(state.userData).length != 0 ? true : false
    const loc = window.location;

    const handleOpen = () => {
        isLoggedIn ? setForkOpen(true) : setLoginOpen(true)
    };

    const handleAuthOpen = () => {
      setForkOpen(true)
    }
    
    const handleClose = () => {
      setForkOpen(false)
      setLoginOpen(false)
    };

    const handleMediaChange = (event) => {
      setMediaOption(event.target.value);
    };


    useEffect(() => {
      if(state.app.forkingStory && state.stories.activeStory && state.stories.activeStory.portal_id && loc.pathname.indexOf(state.stories.activeStory.portal_id) == -1) {
        toast.success(
          Messages.success.projectForkedSuccessFully,
        );
        history.push('/p/'+state.stories.activeStory.portal_id)
        
        dispatch({ type:"CLEAR_FORK", payload:{} })
      }
    }, [state.app.forkingStory, state.stories.activeStory])

      function forkIt() {
        const userRef = state.userData.ref
        const story = state.stories.activeStory;
        const storyRef = story.ref
        const ownerIsForking = state.app.userIsStoryOwner
        const portalId = nanoid(12)
        /*(ownerIsForking) ? 
        userRef.collection('stories').doc(storyId) : 
        userRef.collection('stories').doc();*/
        const created = new Date()
        console.dir("fork it")
        try {
            const newStory = {
                title: formatDate(created, 'title'),
                portal_id: portalId,
                created_at: created,
                parts: state.stories.activeStory.parts,
                description: story.description ? story.description : "",
                last_cut: null,
            }
            if ("last_audio" in story) {
                newStory["last_audio"] = story.last_audio;
            }
            if (!userRef) {console.log(""); return} else {console.log("FORK USER ", userRef.id)}
            if (!storyRef) {console.log("FORK RETURN NO STORY"); return} else {console.log("FORK STORY", storyRef.id)}
            

            dispatch({ type: "FORK_STORY", payload: {"mediaOption": mediaOption, "userRef": userRef.ref, "storyRef": storyRef, "newStory": newStory }});
            setForkOpen(false);
        } catch(error) {
            toast.error(
                Messages.error.projectForkFailed,
            );
        }
        
        // console.log("FORK PREV STORY ", story)
        // console.log("FORK NEW STORY ", newStory)
        

        /*toast.promise(forkPromise, {
          loading: Messages.loading.waitingForFork,
          success: Messages.success.projectForkedSuccessFully,
          error: Messages.error.projectForkFailed,
        })*/
    }

    const forkDialog =         <Dialog
        open={forkOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fork Project</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Do you want to fork this project to make a separate copy?
          </DialogContentText>

          <RadioGroup value={mediaOption} onChange={handleMediaChange} sx={{ my: 3 }}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={'auto'}>
                      <FormControlLabel value="No Media" control={<Radio />} label="No Media" />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                      <FormControlLabel value="All Media" control={<Radio />} label="All Media" />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                      <FormControlLabel value="Selected Media" control={<Radio />} label="Selected Media" />
                  </Grid>
              </Grid>
          </RadioGroup>
        
                
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            No
          </Button>
          <Button variant="contained" onClick={forkIt} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    const loginDialog =  <Dialog
        open={loginOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fork Project</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          <AuthBox version="login-modal" redirect={loc.pathname} authFunc={handleAuthOpen} 
            value_prop="Log in to fork a copy of this project." />
        </DialogContent>
      </Dialog>

    return (
        <>
        <Link href="#" onClick={handleOpen} sx={{ display:'block', marginLeft:'10px' }} title="Fork Project">
            <AltRouteIcon sx={{ color:theme.palette.primary.contrastText }} />
        </Link>
        { isLoggedIn ? forkDialog : loginDialog }
      </>
    );
} 