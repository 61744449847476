import "./App.css";
import "./styles/style.scss";

import { Route, Switch } from "react-router-dom";

import About from "./components/about"; // not used, but works, and could be reused for a demo
import DynamicWrapper from "./components/DynamicWrapper";
import ExampleProduction from "./components/example"; // static example. needs a refresh
import Home from "./components/home"; // not used, but works, and could be reused for a demo
import ImageSelector from "./components/image-selector";
// import StaticScene from './pages/Scene';
// import Layout from './components/Layout'
// import Recorder from './components/recorder';          // not maintained
import Instructions from "./graveyard/instructions"; // not maintained
import Login from "./components/login";
import NewProjectSelector from "./components/new";
import Preview from "./components/preview";
import PrivateRoute from "./components/private-route";
import Produce from "./components/produce";
import Produce2 from "./components/produce2"; // like a feature branch for minimal user instruction.  should generalize & intregrate to produce
import React from "react";
import RoughParts from "./components/parts";
import Scene from "./components/scene";
import SettingsInspector from "./components/settings";
import Storyboards from "./components/storyboards";
import Takes from "./components/takes";
import Strip from './components/Strip';

// Hello World

function App() {
  return (
    <Switch>
      {/* private */}
      <PrivateRoute path="/" exact component={DynamicWrapper} />
      <PrivateRoute path="/new" exact component={DynamicWrapper} />
      <PrivateRoute path="/idea" exact component={RoughParts} />
      <PrivateRoute path="/plan-bak" exact component={Scene} />
      {/*<PrivateRoute path="/demo1" exact component={SceneDemo1} />        // supported client facing demo preview */}
      {/*<PrivateRoute path="/p/:id" exact component={Produce} />*/}
      <PrivateRoute path="/image" exact component={ImageSelector} />
      <PrivateRoute path="/settings" exact component={SettingsInspector} />
      <PrivateRoute path="/storyboards" exact component={Storyboards} />
      <Route path="/p/:id/strip" exact component={Strip} />
      // public
      <Route path="/ptl/image" exact component={ImageSelector} /> // when we click on takes from the portal with unauthed. Could force a sign in first.
      <Route path="/p/J-8cN_bPZHjm0u8R3ukSF" children={<Produce2 />} /> // custom link for one old event ... ok if its blank on other firebase projects
      <Route path="/p/:id/preview" exact children={<Preview />} />
      <Route path="/p/:id/:shotID" exact children={(props) => <Takes {...props} />} />
      <Route path="/p/:id" exact children={<Produce />} /> {/* primary public portal */}
      {/*<PrivateRoute path="/p/:id" exact children={<Produce />} />     // does not seem to auth private ..*/}
      {/* auth */}
      <Route path="/login" exact component={Login} />
      <Route path="/signup" exact component={Login} />
      <Route path="/forgotPassword" exact component={Login} />
      <Route path="/example" exact component={ExampleProduction} />
      {/* non-essential pages used for development and tests */}
      <Route path="/zela_bday_info" exact component={Instructions} />
      {/* historical bits */}
      {/*  // still functional at 2021, but moved to graveyard to not maintain react video recorder dependencies
            <Route path="/zela_bday" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/recorder" exact component={Recorder}/>
            <PrivateRoute path="/recorder" exact component={Recorder}/>
            <Route path="/dnd_test" exact component={Layout} />
            <Route path="/static_scene" exact component={StaticScene} />
            */}
    </Switch>
  );
}

export default App;
