import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import AppContext from "../utils/app-context";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../utils/utility.js";
import { nanoid } from "nanoid";
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import assets from "../utils/assets";

function NewProjectSelector() {
  const { state, dispatch } = useContext(AppContext);
  const stories = state.stories.storyList;
  const history = useHistory();

  const emptyStory = (init_shot_id) => {
    const created = new Date();
    return {
      parts: [{ title: "Storyboard", order: [init_shot_id] }],
      last_cut: null,
      title: formatDate(created, "title"),
      portal_id: nanoid(12),
      created_at: created.getTime(),
      deleted_at: null, 
      shots: [
        {
          header: "Shot 1",
          description: "HERO starts JOURNEY",
          meta: null,
          image: assets["empty_thumbnail"],
          id: init_shot_id,
          deleted_at: null,
        },
      ],
    };
  };

  const createSample = () => {
    dispatch({ type: "NEW_ACTIVE_STORY", payload: { newStory: state.story } });
    history.push('/p/'+state.story.portal_id)
  }

  const handleContinueEmpty = () => {
    const id = uuidv4();
    const story = emptyStory(id);
    dispatch({ type: "NEW_ACTIVE_STORY", payload: { newStory: story } });
    history.push('/p/'+story.portal_id)
  };

  const CancelNewStoryAndContinue = () => {
    history.push("/storyboards");
  };

  return (
    <div className="app_container">
      <Box
        component="header"
        sx={{
          display: "flex",
          padding: "0 1em",
          justifyContent: "space-between",
          borderBottom: "1px solid " + theme.palette.primary.main,
        }}
      >
        <Typography variant="h2">New storyboard</Typography>
        {stories.length && stories.some((story) => story.created_at) ? (
          <CloseIcon
            onClick={CancelNewStoryAndContinue}
            sx={{ alignSelf: "center", cursor: "pointer" }}
          />
        ) : null}
      </Box>
      <Box sx={{ textAlign: "center", height: "80vh", display: "flex" }}>
        <Box sx={{ maxWidth: "450px", margin: "0 auto", alignSelf: "center" }}>
          <div>
            <img src={"logo192.png"} alt="" width={98} />
          </div>
          <Typography variant="brand" sx={{ marginBottom: "15px" }}>
            10zebra
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: "30px" }}>
            Do you want to start a new storyboard?
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              size="large"
              sx={{ marginRight: "25px" }}
              onClick={createSample}
            >
              View Sample
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={handleContinueEmpty}
            >
              New Storyboard
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default NewProjectSelector;
