import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage} from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
// Track API version in the client
const API_VERSION = '1.0.2' 


const dev10zFirebaseConfig = {
  apiKey: "AIzaSyC7nYYzziBHtU0QPqOnJ222HyeCxo0nW_w",
  authDomain: "v0zebra-dev.firebaseapp.com",
  projectId: "v0zebra-dev",
  storageBucket: "v0zebra-dev.appspot.com",
  messagingSenderId: "731245445150",
  appId: "1:731245445150:web:6c706574ad7e9382deee0f",
  measurementId: "G-ZPBSW1TR6H"
};

const prod10zfirebaseConfig = {
  apiKey: "AIzaSyA-vt3-NbwUxfQ1YHqQFxCez8FbCnmYzaw",
  authDomain: "v0zebra.firebaseapp.com",
  projectId: "v0zebra",
  storageBucket: "v0zebra.appspot.com",
  messagingSenderId: "295799406494",
  appId: "1:295799406494:web:597982141075d9f5eba08f",
  measurementId: "G-C154RZ28WB"
};

const okvFirebaseConfig = {
    apiKey: "AIzaSyCHbrmGrEMLeyzQFLOYlNb6QfNPw4I0fkk",
    authDomain: "okapi-webdev.firebaseapp.com",
    databaseURL: "https://okapi-webdev.firebaseio.com",
    projectId: "okapi-webdev",
    storageBucket: "okapi-webdev.appspot.com",
    messagingSenderId: "499502591398",
    appId: "1:499502591398:web:00b5bca0c864dccc48f367",
    measurementId: "G-SCS4HXVDSS"
};

const rnbwFirebaseConfig = {
    apiKey: "AIzaSyCeZyDBcDH9fquplEGhfYY4xc8EoGbvK0Y",
    authDomain: "rnbw-art.firebaseapp.com",
    projectId: "rnbw-art",
    storageBucket: "rnbw-art.appspot.com",
    messagingSenderId: "150026766403",
    appId: "1:150026766403:web:5133c8c3c05662ac3e2cf6",
    measurementId: "G-14VRXLRMF3"
}

// console.log("NODE_ENV", process.env.NODE_ENV)
// console.log("REACT_APP_FIREBASE_ENV", process.env.REACT_APP_FIREBASE_ENV)
// console.log("REACT_APP_FLAVOR", process.env.REACT_APP_FLAVOR)
// console.log("REACT_APP_VERSION", process.env.REACT_APP_VERSION)

let firebaseConfig = okvFirebaseConfig

switch(process.env.REACT_APP_FLAVOR) {
  case 'okv':
    firebaseConfig = okvFirebaseConfig;
    break;
  case '10z':
    if (process.env.REACT_APP_FIREBASE_ENV === 'prod'){
        firebaseConfig = prod10zfirebaseConfig;
    } else {
        firebaseConfig = dev10zFirebaseConfig;
    }
    break;
  case 'rnbw':
    firebaseConfig = rnbwFirebaseConfig;
    break;
  default:
    firebaseConfig = dev10zFirebaseConfig;
}

//console.log("projectId", firebaseConfig.projectId, API_VERSION)


export const config = firebaseConfig

const firebase = initializeApp(config);

export default firebase;
export const db = getFirestore(firebase);
export const auth = getAuth(firebase);
export const storage = getStorage(firebase);
export const analytics = getAnalytics(firebase)

export const apiCall = (name) => {
    const callable = getFunctions(firebase).httpsCallable(name);
    const wrapper = (data) => {
        data.apiVersion = API_VERSION
        return callable(data)
    }
    return wrapper
}





