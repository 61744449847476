import React, { useState, useContext } from 'react'
import AppContext from "../utils/app-context";
import { MenuList, MenuItem, Box, Button, withTheme, Link, Typography } from '@mui/material'
import { useLocation, useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { nanoid } from "nanoid";

import theme from '../styles/theme'

const Breadcrumbs = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const isAdmin = props.admin || false;
  const story = state.stories.activeStory
  const portal_id = (state.stories.activeStory.portal_id) ? state.stories.activeStory.portal_id : state.app.portal.id; 
  const regMenuData = [ {title:"Create", href:'/p/' + portal_id, click:startCollecting}, {title:"Preview", href:'/p/' + portal_id + '/preview', click:goToPreview} ]
  const adminMenuData = [ {title:"Back to Storyboards", href:"/storyboards" } ];
  const menuData = isAdmin ? adminMenuData : regMenuData;
  let menu =[]; 
  const location = useLocation();
  const owner = props.owner;
  
  const textStyles = {
    fontSize:'24px', 
    textAlign:'center', 
    color:theme.palette.primary.dark, 
    [theme.breakpoints.down('md')]: {
      fontSize:'14px', 
    }
  }
  const wrapperStyles = {
    display:'inline-flex', 
    alignItems:'center', 
    textAlign:'center', 
    justifyContent:'center', 
    padding:0, 
    flexWrap:"wrap",
    width:'100%',
    [theme.breakpoints.down('md')]: {
      padding:'10px 0'
    }
  }
  const itemStyles = {
    textDecoration: 'none',
    background: 'none',
    textAlign: 'center', 
    padding: '0 10px',
    minHeight: 0,
    display: 'inline-flex',
    alignItems: 'center',
    '&.arrow': {
      padding:'0 15px', 
    }, 
    '&.Mui-selected, &.Mui-selected a, &.Mui-selected a:hover, &.Mui-selected:hover': {
      background:'none', 
      fontWeight:'700', 
      textDecoration:'none'
    }, 
    'a': {
      textDecoration:'none',
    }, 
    '&:hover, a:hover': {
      background:'none',
      textDecoration:'underline',
      cursor:'default'
    }, 
    'a:hover': {
      cursor:'pointer'
    }, 
    [theme.breakpoints.down('md')]: {
      justifyContent:'center',
    }
  }
  function navigate(url, e) {
    e.preventDefault();
    history.push(url);
  }
  //if(!owner) menuData.shift()

  function createID() {
    let new_portal_id = nanoid(12)
    dispatch({type: "ADD_REQUESTED_PORTAL", payload: {portal_id: new_portal_id}});
    return new_portal_id;
  }

  function startCollecting() {
      //let portal_id = story.portal_id
      let portal_id = state.stories.activeStory.portal_id

      if (!portal_id || portal_id === null){
          portal_id = createID();
      }

      //pushActiveStoryToCloud()
      //history.push('/collect');
      history.push('/p/' + portal_id);
  }

  function goToPreview() {
    let portal_id = state.stories.activeStory.portal_id

    if (!portal_id || portal_id === null){
        portal_id = createID();
    }
    history.push('/p/' + portal_id + "/preview");
  }

   menuData.map((item, key) => {
    menu.push(<MenuItem disableRipple selected={item.href == location.pathname} sx={itemStyles}>
      <Link    disableRipple onClick={ () => {
        
          if(item.click) item.click()
         else if(item.href) history.push(item.href)
        }    }>
        <Typography  variant="span" sx={ textStyles }>{item.title}</Typography>
      </Link>
      </MenuItem>);
      if(key != menuData.length-1) menu.push(<MenuItem className="arrow" disableRipple selected={item.href == location.pathname} sx={itemStyles}><ArrowForwardIcon sx={{ fontSize:'12px' }} /></MenuItem>)
  });


  return <MenuList sx={ wrapperStyles }>
    {menu}
  </MenuList>
}

export default Breadcrumbs