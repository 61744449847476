
export function formatDate (date, format) {
  let dateStr;
  if(format == 'title') {
    dateStr = date.getHours() + ':' + ((date.getMinutes() < 10) ? '0'+date.getMinutes() : date.getMinutes()) + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getDate() + ', `' + date.getFullYear().toString().slice(2)
  }

  return dateStr;
}

export const sortTakes = (takes) => {
  return takes.sort((a, b) => {
    let timeA = a.modified_at || a.uploaded_at;
    let timeB = b.modified_at || b.uploaded_at;
    if (timeA && !timeB) return 1;
    if (!timeA && timeB) return -1;
    if (!timeA && !timeB) return 1;
    return timeB.seconds - timeA.seconds;
  });
};
