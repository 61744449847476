import { v4 as uuidv4 } from 'uuid';
import assets from "../utils/assets";
import Messages from "../constants/app";
import toast from "react-hot-toast";
import { storage } from "../utils/firebase"
import { ref, uploadBytesResumable } from "@firebase/storage"

export const uploadBlob = (props) => {
    const {blob, dispatch, path, firebase} = props;
    const storageRef = ref(storage, path);

    dispatch({ type: "START_UPLOAD", payload: { totalBytes: blob.size, totalFiles: 1 } });

    let uploadResolve, uploadReject;
    const uploadPromise = new Promise((resolve, reject) => {
        uploadResolve = resolve;
        uploadReject = reject;
    });
    
    //const blobRef = storageRef.child(path)

    const uploadNext = (snapshot) => {
        // const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        const bytes = snapshot.bytesTransferred;
        dispatch({ type: "FILE_UPLOAD_UPDATE", payload: { path, bytes } });
    }

    const uploadError = (error) => {
        console.log(`Error uploading blob`, error)
        uploadReject(error)
    }

    const uploadComplete = () => {
        dispatch({ type: "FILE_UPLOAD_SUCCESS", payload: {} });
        uploadResolve('upload completed');
    }

    const blobUploadTask = uploadBytesResumable(storageRef, blob);

    blobUploadTask.on('state_changed', snapshot => {
        switch(snapshot.state) {
            case 'running': 
                uploadNext(snapshot)
                break;
        }
    }, (error) => {
        uploadError()
    }, () => {
        uploadComplete()
    });

    return uploadPromise;
}

export const videoUpload = async (props) => {
    const {file, shot_id, state, dispatch, storyImport, currentUser, firebase} = props;
    const file_MB = file.size / 1000000
    const alert_thresh_MB = 25
    const max_MB = (state.app.userIsStoryOwner && state.app.settings.userCanUploadLargeFiles) ? 250 : 50
    if (file_MB > max_MB) {
        alert(`Please use shorter video clips. ${file_MB.toPrecision(3)}MB is over the ${max_MB}MB limit for this project's settings.`)
    } else {
        if (file_MB > alert_thresh_MB) {
        alert(`This file is larger than expected for the recommended 1-6 second video. Upload will resume.`)
        }
        if(storyImport) console.log("onFileUploadChange ... " + "insert story" + " " + file_MB.toPrecision(3) + "MB")
        else console.log("onFileUploadChange ... " + shot_id + " " + file_MB.toPrecision(3) + "MB")

        const id = uuidv4();
        const storageDir = "media"
        //const path = `${storageDir}/${filename}.mp4`

        let oid = "unknown"
        let sid = "unknown"
        if (state.stories.activeStory && state.stories.activeStory.ref) {
        // const story_path = state.stories.activeStory.ref.u_.path 
        // const OID_ind = story_path.segments.findIndex((item) => item == "users") + 1 
        
        const story_path_array = state.stories.activeStory.ref.path.split("/");

        //console.log("story_path", story_path_array)
        const OID_ind = story_path_array.findIndex((item) => item == "users") + 1 
        //console.log("OID_ind", OID_in dd)
        oid = story_path_array[OID_ind]
        //console.log("OID", oid)

        const SID_ind = story_path_array.findIndex((item) => item == "stories") + 1 
        //console.log("SID_ind", SID_ind)
        sid = story_path_array[SID_ind]
        //console.log("SID", sid)
        //alert(SID_ind)
        //alert(OID_ind)
        }

        const uploader_id = currentUser ? currentUser.uid : "unknown"
        const path = (storyImport) ? `${storageDir}/us/${oid}/st/${sid}/up/${uploader_id}/imports/${id}.mp4` : `${storageDir}/us/${oid}/st/${sid}/up/${uploader_id}/ta/${id}.mp4`

        const uploadPromise = uploadBlob({blob:file, dispatch:dispatch, path:path, firebase:firebase})
        let result = await uploadPromise;

        if (result ==='upload completed') {
            //event.target.value = null
            if(storyImport) {
                const payload = {video_path: path, 
                    thumbnail:  assets['processing_thumb'],
                    sid: sid,
                    id: id,
                    type:"storyboard", 
                    storyRef: state.stories.activeStory.ref
                }
                dispatch({ type: "STORY_IMPORT", payload: payload })
                toast.success(Messages.info.autoStoryBoardStarted);
            } else {
                const payload = {video_path: path, 
                    thumbnail:  assets['processing_thumb'],
                    shot_id: shot_id,
                    take_id: id,
                }
                dispatch({ type: "ADD_TAKE_TO_SHOT", payload: payload })
                //setSelected();
                //setTakesCount(takesCount + 1)
                const lastPayload = {
                    take_id: id, 
                    image: assets['processing_thumb'],
                    stream_path: path, 
                    shot_id: shot_id
                }; 
                dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: lastPayload })
                toast.success(Messages.info.mediaUploadCompleted);
            }
        }
    }
}

export const  imageUpload = async (props) => {
    const {file, shot_id, state, dispatch, currentUser, firebase, path} = props
    const file_MB = file.size / 1000000
    const max_MB = 15
    if (file_MB > max_MB) {
        alert(`Please use a smaller file size.  Check resolution or compression type (JPG, PNG, WEBP). ${file_MB.toPrecision(3)}MB is over the ${max_MB}MB limit for this project's settings.`)
    } else {
        const take_id = uuidv4();
        const storageDir = "media"
        let oid = "unknown"
        let sid = "unknown"
        if (state?.stories?.activeStory && state?.stories?.activeStory?.ref) {
            const story_path_array = state.stories.activeStory.ref.path.split("/");
            const OID_ind = story_path_array.findIndex((item) => item == "users") + 1 
            oid = story_path_array[OID_ind]
    
            const SID_ind = story_path_array.findIndex((item) => item == "stories") + 1 
            sid = story_path_array[SID_ind]
        }
        
        if (['image/jpeg','image/webp','image/png'].includes(file.type) ) {
            var ext = file.type.substr(file.type.lastIndexOf('/') + 1)
        }
        else {               
            alert("Unsupported file type.  Images should be jpeg, webp or png, not '" + file.type + "' -- " + file.name )
            // var re = /(?:\.([^.]+))?$/;
            // var ext = re.exec(file.name)[1]
        }

        const uploader_id = currentUser ? currentUser.uid : "unknown"
        const image_path = `${storageDir}/us/${oid}/st/${sid}/up/${uploader_id}/ta/${take_id}.${ext}`
        if(image_path) {
            const uploadPromise = uploadBlob({blob:file, dispatch:dispatch, path:image_path, firebase:firebase})
            let result = await uploadPromise;
    
            if (result ==='upload completed') {
                const im_payload = {image_path: image_path, 
                    thumbnail:  assets['processing_thumb'],
                    shot_id: shot_id,
                    take_id: take_id,
                }
                event.target.value = null
                dispatch({ type: "ADD_IMAGE_TO_SHOT", payload: im_payload })
                //setSelected();
                //setTakesCount(takesCount + 1)
                const lastPayload = {
                    take_id: take_id, 
                    image: assets['processing_thumb'],
                    stream_path: path, 
                    shot_id: shot_id
                }; 
                dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: lastPayload })
                toast.success(Messages.info.mediaUploadCompleted);
            }
        }
    }
}
export const debounce = (func, wait = 300) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  }
