import React, { useContext, useState, useEffect } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
//import { withStyles } from '@mui/styles';
import theme from "../styles/theme";

import AppContext from "../utils/app-context";

//  custom Alert styling avoided.   Using default Snackbar instead.
// const useStyles = makeStyles({
//     alert: {
//         position: "absolute",
//         top: '30px',
//         left: '50%',
//         zIndex: 100,
//         transform: 'translate(-50%, 0%)',
//         width: "600px",
//         maxWidth: "95vw",
//         boxShadow: "0 0.5rem 1rem #808080",
//     }
// });

export default function AlertImportStoryboardStatus() {


    const { state } = useContext(AppContext);
    const [message, setMessage] = useState(["Uploading and cropping", "", "image "]);
    const [progress, setProgress] = useState(0);
    const [framesProgress, setFramesProgress] = useState(0);

    const [open, setOpen] = React.useState(true);


    //const bytesReducer = (acc, curr) => acc + curr;

    const data = state.app.importing.data || {}

    const sizeInMB = (sizeInBytes) => (sizeInBytes / (1024 * 1024)).toFixed(2);

    useEffect(() => {
        if (data.totalFiles > 1) {
            setMessage(["Importing", data.totalFiles, "videos "]);
        } else {
            setMessage(["Creating storyboard", "", "... this may take a while."])
        }
        if (data.processedFrames) {
            //let bytes = Object.values(data.fileBytes).reduce(bytesReducer, 0);
            setProgress(100 * data.processedFrames / data.totalFrames);
            setFramesProgress(data.processedFrames + ' / ' + data.totalFrames + ' frames');
        }

    }, [data.totalFiles, data.fileBytes, data.totalBytes, framesProgress])

    const beforeUnload = (evt) => {
        evt.preventDefault();
        evt.returnValue = true;
    }

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnload);

        return () => window.removeEventListener('beforeunload', beforeUnload);
    })

    if (!state.app.importing) {
        return (<></>)
    }

    // Alternate message with progress bar that updates on processedFrames
    // {message[0]} <b>{message[1]}</b> {message[2]} <b> {framesProgress} </b><br />
    //<LinearProgress variant="determinate" value={progress} />
    return (
         <Snackbar open={open} >
            <Alert
                onClose={() => {
                    setOpen(false);
                    //dispatch({type: "IMPORT_SUCCESS", payload: {}});  // fake success to make alert go away for now. 
                }}
                key={'alert'}
                severity='info'
                className='nothingCustom'>
                {message[0]} {message[2]} 
            </Alert>
         </Snackbar>
    )
}